import React from "react"
import Layout from "../../components/Layout/layout"
import StatusPage from "../../Views/Website/EmploymentStatus/EmploymentStatus";
import { StatusColours } from "../../Enums/EmploymentStatus";
import { Router } from "@reach/router";
import EmploymentStatusList from "../../Views/Website/EmploymentStatus/EmploymentStatusList/EmploymentStatusList";

const EmploymentStatusPage: React.FC = (props: any) => {
  return (
    <Layout>
      <Router basepath="/EmploymentStatus" className={"router"}>
        <PrivateRoute path="/" component={() => <EmploymentStatusList />} />
        {
          StatusColours.filter(s => s.Information.Page).map((s) => {
            return <PrivateRoute path={s.Status} component={() => <StatusPage Status={s} location={props.location.href} />} />
          })
        }
      </Router>
    </Layout>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default EmploymentStatusPage;