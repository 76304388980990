import React, { useContext, useEffect, useState } from 'react';
import * as styles from './EmploymentStatus.module.scss';
import { UserContext } from '../../../Context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { EmploymentStatus, StatusColour, StatusColours } from '../../../Enums/EmploymentStatus';
import { Helmet } from 'react-helmet';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import statusImage from '../../../assets/IMG/EmploeeStatus.png';

interface Props {
  Status: StatusColour;
  location: string;
}

const StatusPage: React.FC<Props> = ({ Status, location }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const shareURL: string = location ? `${location}` : "";

  const Copy = async () => {
    await navigator.clipboard.writeText(shareURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000)
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: What Is {Status.Information.Title}</title><meta charSet='utf-8' />
      <meta property="og:description" content={`${Status.Information.Description}`} />
      <meta property="og:title" content={`PrimeHR :: What Is ${Status.Information.Title}`} />
      <meta property="og:site_name" content="PrimeHR" />
      <meta property="og:url" content={`${location}`} />
    </Helmet>

    <Banner />
    <Breadcrumb Trail={[
      { To: `/EmploymentStatus`, Text: `What Is...` },
      { To: ``, Text: `${Status.Information.Title}` }
    ]} />

    {Status.Status ? <div className={styles.Packages}>
      <div className={styles.Package}>
        <div className={styles.PackageInfo}>
          <div className={styles.PackageInfoInner}>
            <h2>What Is</h2>
            <h1 style={{ color: Status.Colour }} dangerouslySetInnerHTML={{ __html: `${EmploymentStatus[Status.Status].replace("(", "<small>(").replace(")", ")</small>")}` }}></h1>

            <div className={styles.Apps}>
              <div className={styles.AppsBlock}>
                {
                  <div className={styles.App}>
                    <i style={{ color: Status.Colour }}>
                      <FontAwesomeIcon icon={faUserTag} />
                    </i>
                    <p dangerouslySetInnerHTML={{ __html: `${EmploymentStatus[Status.Status].replace("(", "<small>(").replace(")", ")</small>")}` }}></p>
                  </div>
                }
              </div>
            </div>

            <pre>{Status.Information.Description}</pre>

            <h3>Where is this Applied?</h3>

            <pre>In our PrimeHR Portal, this status and other statuses will appear in reference to the Employee. Some statuses will be also applied to certain Calendar events where an Employee might be on leave during a set period of time. An Employee's status can be seen on the Employee's data record.</pre>

            <img loading={"lazy"} src={statusImage} />
          </div>
        </div>
      </div>

      <div className={styles.Side}>
        <div className={`${styles.Share} ${copied ? styles.Active : ""}`}>
          <input value={shareURL} type="text" readOnly={true} />
          <button onClick={() => Copy()}>{copied ? "Copied!" : "Copy"}</button>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
        </div>

        <div className={styles.SideInfo}>
          <h4>Found This Information Useful?</h4>
          <p>If you found this Information or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Bronze"}>Bronze</Link>, <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

          <Link to="/Register" className={styles.Button}>
            Create an Account
          </Link>
        </div>

        <div className={styles.MoreFrom}>
          <div className={styles.MoreFromInner}>
            <h1>What Is...</h1>
            <ul>
              {
                StatusColours.filter(s => s.Information.Page && s.Status !== Status.Status).map((s, i) => {
                  return <li><Link to={s.Information.Page}>{s.Information.Title}</Link></li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div> : <></>}
  </>);
};

export default StatusPage;
