import React, { useState } from 'react';
import * as styles from './EmploymentStatusList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { EmploymentStatus, StatusColour, StatusColours } from '../../../../Enums/EmploymentStatus';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';

const EmploymentStatusList: React.FC = () => {
  const [search, setSearch] = useState<string>("");

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: What Is...</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[{ To: "", Text: "What Is..." }]} />

    <div className={styles.Documents}>
      <div className={styles.DocumentsInner}>
        <div className={styles.Freebies}>
          <div className={styles.FreebiesInner}>
            <h1>What Is...</h1>
            <p>Our PrimeHR Employee Management System uses a range of Employment Status' which you as an employer may need when onboarding a new Employee or removing one from your organisation. However sometimes it can be confusing to know what each Status means, so we have started to compile all our useful knowledge into our "What Is..." section.</p>

            <div className={styles.Search}>
              <div className={styles.SearchInput}>
                <i><FontAwesomeIcon icon={faSearch} /></i>
                <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>

            <div className={styles.Articles}>
              <div className={styles.ArticlesInner}>
                {
                  StatusColours.filter(s => EmploymentStatus[s.Status].toLowerCase().includes(search.toLowerCase()) && s.Information.Page).length ? <>{
                    StatusColours.filter(s => EmploymentStatus[s.Status].toLowerCase().includes(search.toLowerCase()) && s.Information.Page).map((s: StatusColour) => {
                      return <Link to={s.Information.Page} className={styles.Freebie}>
                        <div className={styles.FreebieInner}>
                          <h2 style={{ color: s.Colour }}>{EmploymentStatus[s.Status]}</h2>
                          <p>{s.Information.Description}</p>
                        </div>
                        <i>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </i>
                      </Link>
                    })
                  }</> : <div className={styles.Message}>
                    <i>
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </i>
                    <h1>No Status Found</h1>
                    <p>We Couldn't find what you were looking for. Try Again later for any potential updates!</p>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default EmploymentStatusList;
