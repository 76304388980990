// extracted by mini-css-extract-plugin
export var Active = "EmploymentStatus-module--Active--8521a";
export var App = "EmploymentStatus-module--App--22395";
export var Apps = "EmploymentStatus-module--Apps--69b7d";
export var AppsBlock = "EmploymentStatus-module--AppsBlock--48132";
export var Button = "EmploymentStatus-module--Button--4f024";
export var Images = "EmploymentStatus-module--Images--b5a18";
export var Loading = "EmploymentStatus-module--Loading--57cad";
export var Message = "EmploymentStatus-module--Message--1152d";
export var MoreFrom = "EmploymentStatus-module--MoreFrom--a84b5";
export var MoreFromInner = "EmploymentStatus-module--MoreFromInner--294c0";
export var Package = "EmploymentStatus-module--Package--6a9e7";
export var PackageDoc = "EmploymentStatus-module--PackageDoc--37f95";
export var PackageInfo = "EmploymentStatus-module--PackageInfo--cf5d0";
export var PackageInfoInner = "EmploymentStatus-module--PackageInfoInner--2a9cb";
export var PackageInner = "EmploymentStatus-module--PackageInner--3cb9c";
export var Packages = "EmploymentStatus-module--Packages--40610";
export var Rating = "EmploymentStatus-module--Rating--37936";
export var RatingInner = "EmploymentStatus-module--RatingInner--b305c";
export var RatingPerc = "EmploymentStatus-module--RatingPerc--c9860";
export var RatingStars = "EmploymentStatus-module--RatingStars--2b866";
export var Ratings = "EmploymentStatus-module--Ratings--a75b4";
export var RatingsForm = "EmploymentStatus-module--RatingsForm--80275";
export var RatingsFormStars = "EmploymentStatus-module--RatingsFormStars--8882e";
export var RatingsList = "EmploymentStatus-module--RatingsList--4e35e";
export var RatingsListButton = "EmploymentStatus-module--RatingsListButton--1f0d4";
export var RatingsListCarousel = "EmploymentStatus-module--RatingsListCarousel--99cd2";
export var RatingsListCarouselInner = "EmploymentStatus-module--RatingsListCarouselInner--e3219";
export var RatingsListItem = "EmploymentStatus-module--RatingsListItem--8f270";
export var RatingsOverall = "EmploymentStatus-module--RatingsOverall--44c15";
export var RatingsOverallItem = "EmploymentStatus-module--RatingsOverallItem--4c61b";
export var RatingsOverallItemBar = "EmploymentStatus-module--RatingsOverallItemBar--e8157";
export var RatingsOverallItemBarInner = "EmploymentStatus-module--RatingsOverallItemBarInner--e89fd";
export var Right = "EmploymentStatus-module--Right--90c8b";
export var Share = "EmploymentStatus-module--Share--89ab9";
export var Side = "EmploymentStatus-module--Side--3aa60";
export var SideInfo = "EmploymentStatus-module--SideInfo--153d6";
export var Social = "EmploymentStatus-module--Social--0dcaf";